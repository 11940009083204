import * as React from 'react'
import { graphql } from 'gatsby'
import IndexLayout from '../layouts'
import { get } from 'lodash'
import { SlideshowAcf } from '../models/Slideshow'
import MainContent from '../components/MainContent/MainContent'
import { Helmet } from 'react-helmet'

interface HeaderAcf extends SlideshowAcf {
  main_header?: { source_url: string; alt_text: string }
  sub_texts?: [{ sub_paragraph: string }]
  main_title: string
  image_content: {
    media_details: {
      sizes: {
        large: {
          source_url: string
          alt_text?: string
        }
      }
    }
  }
}
interface PageTemplateProps {
  data: {
    allWordpressPage: {
      edges: [
        {
          node: {
            acf: HeaderAcf
          }
        }
      ]
    }
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  if (!data.allWordpressPage.edges) return null

  const { main_title, main_header, image_content, sub_texts }: HeaderAcf = get(data, 'allWordpressPage.edges[0].node.acf')
  const { large } = image_content.media_details.sizes
  const meta = {
    title: 'Om klimatbutiken | Klimatbutiken',
    description: 'På Felix är vi måna om att producera mat som inte bara smakar gott utan också gör gott för vår planet.'
  }

  return (
    <IndexLayout background="grey">
      <Helmet title={meta.title}>
        <meta property="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="description" content={meta.description} />
      </Helmet>
      <MainContent title={main_title} imageData={main_header} text={sub_texts} pageImage={large} />
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query ($slug: String!) {
    allWordpressPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          acf {
            main_header {
              source_url
              alt_text
            }
            main_title
            image_content {
              media_details {
                sizes {
                  large {
                    source_url
                  }
                }
              }
            }
            sub_texts {
              sub_paragraph
            }
            text_content {
              title
              text
            }
          }
        }
      }
    }
  }
`
